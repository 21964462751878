<template>
	<v-container fluid class="px-5">
		<ViewTitles class="ml-1 mb-4">
			<h2 class="primary--text" slot="title">
				Automação de Relatórios Cliente -
				{{
					$router.currentRoute.query.actionType === "insert"
						? "Nova Configuração"
						: "Editar"
				}}
			</h2>
		</ViewTitles>

		<v-form ref="form">
			<v-card v-bind="propsCard">
				<v-card-title v-bind="propsCardTitle"> Parâmetros Gerais </v-card-title>

				<v-card-text>
					<v-row>
						<v-col v-bind="propsCol">
							<label class="label"> Grupo Financeiro* </label>
							<v-autocomplete
								v-bind="propsAutoComplete"
								v-model="form.financialGroupId"
								:items="financialGroups"
								:loading="loadingFinancialGroups"
								:rules="[rule.required]"
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Modelo de Dados* </label>
							<v-autocomplete
								v-bind="propsAutoComplete"
								v-model="form.dataModel"
								:items="getDataModelTypes"
								:rules="[rule.required]"
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Layout de Relatório* </label>
							<v-autocomplete
								v-bind="propsAutoComplete"
								v-model="form.reportLayout"
								:items="getReportLayoutTypesFilter"
								:rules="[rule.required]"
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Nome da Consulta* </label>
							<!--
                According to activity XiPP-7957, the reportQueryType field
                will initially have a fixed initial value.

                Please remove this comment when adjustments to this field are made.
              -->
							<v-text-field
								id="reportQueryType"
								placeholder="Informe o nome da consulta"
								v-bind="propsTextField"
								value="Movimentações criadas nos últimos 30 dias por Grupo Financeiro"
								:rules="[rule.required]"
								validate-on-blur
								readonly
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Tipo de Integração* </label>
							<!--
                According to activity Xipp-7957, the reportIntegrationType field
                will initially have a fixed initial value as SFTP.

                Please remove the comment when FTP implementation is carried out.
              -->
							<v-autocomplete
								v-bind="propsAutoComplete"
								:items="getIntegrationTypes"
								v-model="form.reportIntegrationType"
								:rules="[rule.required]"
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Periodicidade (Cron Expression)* </label>
							<v-text-field
								id="cron"
								placeholder="0*/?"
								v-bind="propsTextField"
								v-model="form.cronExpression"
								:rules="[rule.required]"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Exibe RH Protegido* </label>
							<v-autocomplete
								v-bind="propsAutoComplete"
								:items="[
									{ name: 'Sim', id: true },
									{ name: 'Não', id: false }
								]"
								v-model="form.showProtectedHr"
								:rules="[rule.isBoolean]"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card v-bind="propsCard">
				<v-card-title v-bind="propsCardTitle">
					Tipo de Integração: SFTP
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col v-bind="propsCol">
							<label class="label"> URL (Hostname)* </label>
							<v-text-field
								v-bind="propsTextField"
								v-model="form.integrationUrl"
								id="url"
								placeholder="Informe a URL"
								:rules="[rule.required]"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Porta* </label>
							<v-text-field
								id="port"
								placeholder="Informe a porta"
								v-bind="propsTextField"
								v-model="form.integrationPort"
								v-mask="'#####'"
								:rules="[rule.required]"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Usuário* </label>
							<v-text-field
								id="user"
								placeholder="Informe o nome da consulta"
								v-bind="propsTextField"
								v-model="form.integrationUser"
								:rules="[rule.required]"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Senha* </label>
							<v-text-field
								id="password"
								placeholder="Informe a senha"
								:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
								:type="showPassword ? 'text' : 'password'"
								:rules="[rule.required]"
								v-bind="propsTextField"
								v-model="form.integrationPassword"
								@click:append="showPassword = !showPassword"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Diretório Raiz* </label>
							<v-text-field
								id="path"
								placeholder="Informe o caminho do arquivo"
								v-bind="propsTextField"
								v-model="form.integrationDirectory"
								:rules="[rule.required]"
								validate-on-blur
							/>
						</v-col>
						<v-col v-bind="propsCol">
							<label class="label"> Hierarquia Pasta* </label>
							<v-autocomplete
								v-bind="propsAutoComplete"
								v-model="form.integrationFolderHierarchy"
								:items="getFolderHierarchyTypes"
								:rules="[rule.required]"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<v-footer class="my-5 pr-3">
			<v-row class="d-flex justify-end">
				<v-col v-bind="propsColFooter">
					<v-btn
						x-large
						block
						outlined
						color="textPrimary"
						@click="onClickBack()"
					>
						Voltar
					</v-btn>
				</v-col>
				<v-col v-bind="propsColFooter" class="pr-0">
					<v-btn
						x-large
						block
						:loading="loadingForm"
						color="textPrimary"
						class="white--text"
						@click="saveForm()"
					>
						Salvar
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
    <SnackbarCustomize ref="SnackbarCustomize" />
	</v-container>
</template>
<script>
import Rules from "@/shared/validators/formRules"
import ViewTitles from "@/components/PageTitles/ViewTitles.vue"

import VerifyRoutesMixin from "@/shared/mixins/routeManagement/verifyRoutesMixin";
import MovementRecordsMixin from "@/shared/mixins/movementRecords/movementRecordsMixin";
import ReportAutomationMixin from "@/shared/mixins/reportAutomation/reportAutomationMixin";
import FinancialGroupsMixin from "@/shared/mixins/sdi/financialGroupsMixin";

import CustomerReportAutomationService from "@/services-http/sdi/CustomerReportAutomationService";

import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";

import { mapMutations, mapGetters } from "vuex";

export default {
	name: "CustomerReportAutomationForm",

	components: {
		ViewTitles,
    SnackbarCustomize
	},

	props: {
		subtitle: {
			type: String,
			default: ""
		}
	},

	data: () => ({
		loadingFinancialGroups: false,
		showPassword: false,
		loadingForm: false,
		isRHProtegido: false,

    customerReportService: null,

		rule: null,

		form: {},

		propsCol: {
			class: "text-subtitle-1 primary--text font-weight-medium",
			cols: "12",
			md: "4",
			lg: "4",
			xl: "3"
		},
		propsCard: {
			class: "mb-5 rounded-lg"
		},
		propsCardTitle: {
			class: "lable mb-5 title"
		},
		propsAutoComplete: {
			class: "font-weight-regular",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			placeholder: "Selecione",
			color: "textPrimary",
			validateOnBlur: true
		},
		propsTextField: {
			class: "font-weight-regular",
			outlined: true,
			color: "textPrimary"
		},

		propsColFooter: {
			cols: "12",
			xs: "12",
			sm: "6",
			md: "2",
			lg: "2",
			xl: "2"
		},

		financialGroups: []
	}),

	mixins: [
		MovementRecordsMixin,
		ReportAutomationMixin,
		VerifyRoutesMixin,
		FinancialGroupsMixin
	],

	methods: {
		...mapMutations({
			cleanForm: 'customer-report-automation/cleanForm',
			setFormSelected: 'customer-report-automation/setFormSelected',
		}),

		handlePageRefresh() {
      sessionStorage.setItem('customer-form', JSON.stringify(this.form))
    },

    setSessionStoreData() {
      if (sessionStorage.getItem('customer-form')) {
        this.setFormSelected(JSON.parse(sessionStorage.getItem('customer-form')));
        sessionStorage.removeItem('customer-form')
      }
    },

		onClickBack() {
			this.cleanForm()
			this.redirectRouter("CustomerReportAutomation", {
				isRHProtegido: this.isRHProtegido
			})
		},

		async saveForm() {
			if (this.$refs.form.validate()) {
				this.loadingForm = true
        if(this.isInsert()){
          await this.customerReportService.SaveForm(this.form).then((reponse) => {
            if (this.form && this.form.id) {
              this.$refs.SnackbarCustomize.open('success', 'Configuração atualizada com sucesso.');
            } else {
              this.$refs.SnackbarCustomize.open('success', 'Configuração criada com sucesso.');
            }
						this.form = this.getInitialForm();
						this.onClickBack();
            this.loadingForm = false
          }).catch((err) => {
            this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao concluir o processo.');
            this.loadingForm = false
          });
        } else {
          await this.customerReportService.EditForm(this.form).then((reponse) => {
            if (this.form && this.form.id) {
              this.$refs.SnackbarCustomize.open('success', 'Configuração atualizada com sucesso.');
            } else {
              this.$refs.SnackbarCustomize.open('success', 'Configuração criada com sucesso.');
            }
						this.form = this.getInitialForm();
						this.onClickBack();
            this.loadingForm = false
          }).catch((err) => {
            this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao concluir o processo.');
            this.loadingForm = false
          });
        }
				this.loadingForm = false
				return
			}
      this.$refs.SnackbarCustomize.open('error', 'Formulário com campos inválidos');
		},

    isInsert(){
      return this.$router.currentRoute.query.actionType === "insert"
    },

		getInitialForm() {
			return {
			financialGroupId: null,
			dataModel: null,
			reportLayout: null,
			reportQueryType: "DEFAULT",
			reportIntegrationType: "SFTP",
			cronExpression: null,
			showProtectedHr: false,
			integrationUrl: null,
			integrationPort: null,
			integrationUser: null,
			integrationPassword: null,
			integrationDirectory: null,
			integrationFolderHierarchy: "YEAR_MONTH",
			active: true
		}
		}
	},

	computed: {
		...mapGetters({
			customerForm: 'customer-report-automation/customerForm'
		})
	},

	async created() {
		this.rule = new Rules();
		this.form = this.getInitialForm();
    this.customerReportService = new CustomerReportAutomationService();
    this.isRHProtegido = this.$route.query.isRHProtegido;
		await this.getFinancialGroups();
	},

	beforeMount() {
		window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData();
	},

  async mounted() {
    if (this.$router.currentRoute.query.actionType === "update" && Boolean(this.$router.currentRoute.query.id)) {
      this.form = JSON.parse(JSON.stringify(this.customerForm));
    }
  },

	beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  }
}
</script>
